export default class Shain {
  userId: string;
  userName: string;
  password: string;
  authority: string;
  tokuiId: string;
  isValid: string;
  constructor(
    userId = "",
    userName = "",
    password = "",
    authority = "",
    tokuiId = "",
    isValid = ""
  ) {
    this.userId = userId;
    this.userName = userName;
    this.password = password;
    this.authority = authority;
    this.tokuiId = tokuiId;
    this.isValid = isValid;
  }
}
