<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="userId"
          label="ユーザーID"
          prepend-icon="mdi-account"
          outlined
          :readonly="canadd()"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="userName"
          label="ユーザー名"
          prepend-icon="mdi-account"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-autocomplete
          v-model="selectFilterAuthority"
          :items="filterAuthority"
          label="権限"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          return-object
          :disabled="!canEdit('form') || onflg === false"
        ></v-autocomplete>
        <v-text-field
          v-model="password"
          label="パスワード"
          prepend-icon="mdi-lock"
          outlined
          :disabled="!canEdit('pass') || onflg === false"
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
          :disabled="!canEdit('form')"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
        <v-autocomplete
          v-model="selectTokui"
          :items="tokuiList"
          label="得意先"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-account"
          return-object
          :disabled="!canEdit('form')"
        ></v-autocomplete>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import Shain from "@/models/Shain";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const SelectsDelete = {
  Delete: 0,
  Default: 1
};

export default {
  name: "ShainDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      filterAuthority: [],
      selectFilterAuthority: [],
      userId: "",
      userName: "",
      tokuiList: [],
      selectTokui: [],
      onflg: true,
      selectDelete: SelectsDelete.Default,
      SelectsDelete
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    canEdit(param) {
      const authority = this.$store.state.authority;
      const user = this.$store.state.userincode;
      if (authority === "1" || authority === "2") {
        if (param === "pass") {
          if (this.userId === user) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    },
    complete() {
      console.log("complete", this.userId);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.userId);
      this.$loading();
      let pass = "";
      let authority = "";

      if (this.password === "" || this.password === undefined) {
        //pass = this.params.args.password;
        if (this.add) {
          await this.$error("パスワードを入力してください。");
          this.$unloading();
          return;
        }
      } else {
        pass = this.password;
      }
      /*if (pass === undefined) {
        await this.$error("パスワードを入力してください。");
        this.$unloading();
        return;
      }*/
      authority = this.selectFilterAuthority.code;
      if (authority === undefined) {
        await this.$error("権限を選択してください。");
        this.$unloading();
        return;
      }
      const tokuiId = this.selectTokui.code;
      if (!tokuiId) {
        await this.$error("得意先を選択してください。");
        this.$unloading();
        return;
      }
      if (authority === "1" && tokuiId === "0") {
        await this.$error(
          "権限に発注者が選択されている場合、得意先には四国青果以外を選択してください。"
        );
        this.$unloading();
        return;
      }

      try {
        const param = new Shain(
          this.userId,
          this.userName,
          pass,
          authority,
          tokuiId,
          this.selectDelete
        );

        console.log("submit_param", param);
        if (this.add) {
          //await this.postRecord(param);
          const data = await this.$post(this.Paths.shain, param);
        } else {
          //await this.putRecord(param);
          await this.$put(this.Paths.shain, param);
        }
        this.$info("更新しました。", "ユーザーマスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shain";
      // this.$router.push(path);
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getAuthority() {
      console.log("getAutority");
      return await this.$get(this.Paths.codeMasterPulldownAutho);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
    },
    async getTokuiList() {
      console.log("getExist");
      const pulldown = await this.$get(this.Paths.shainTokui);
      console.log(pulldown);
      this.tokuiList = pulldown;
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    const test = this.params.args;
    console.log("args", test);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.filterAuthority = await this.getAuthority();
      await this.getCodeMasterItems();
      this.getPullDownItems();
      await this.getTokuiList();

      if (!this.add) {
        const filterd = this.filterAuthority.filter(
          e => e.code === String(test.authority)
        );
        if (filterd.length > 0) {
          this.selectFilterAuthority = filterd[0];
        }
        const tokuiId = test.tokuiId ? String(test.tokuiId) : "0";
        const filterdTokui = this.tokuiList.filter(e => e.code === tokuiId);
        if (filterdTokui.length > 0) {
          this.selectTokui = filterdTokui[0];
        }

        this.userId = test.userId;
        //this.password = test.password;
        this.userName = test.userName;

        if (test.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
